// extracted by mini-css-extract-plugin
export var categories = "minimal-blog-card-module--categories--bf823";
export var category = "minimal-blog-card-module--category--16918";
export var container = "minimal-blog-card-module--container--428c4";
export var image = "minimal-blog-card-module--image--0c65d";
export var imageContainer = "minimal-blog-card-module--imageContainer--d0c31";
export var innerContainer = "minimal-blog-card-module--innerContainer--3529f";
export var innerContent = "minimal-blog-card-module--innerContent--a0dde";
export var innerTitle = "minimal-blog-card-module--innerTitle--7a53f";
export var largeImage = "minimal-blog-card-module--largeImage--93b80";
export var leftBorder = "minimal-blog-card-module--leftBorder--56dd3";
export var outerTitle = "minimal-blog-card-module--outerTitle--755eb";
export var padding = "minimal-blog-card-module--padding--6db16";
export var yellowCategory = "minimal-blog-card-module--yellowCategory--b765d";