import React from 'react';
import { Layout } from '../components/layout/layout';
import { graphql, HeadFC } from 'gatsby';
import { MinimalBlogCard } from '../components/blog/minimal-blog-card/minimal-blog-card';
import { BlogCardList } from '../components/blog/blog-card-list/blog-card-list';
import { MediumBlogCard } from '../components/blog/medium-blog-card/medium-blog-card';
import { SEO } from '../components/seo/seo';

interface BlogNode {
  frontmatter: {
    categories: string[];
    indicated: boolean;
    title: string;
    date?: string;
    slug: string;
    coverImage: {
      childImageSharp: {
        gatsbyImageData: any;
      };
    };
  };
  excerpt?: string;
}

interface BlogProps {
  data: {
    indicated: {
      nodes: BlogNode[];
    };
    tutorial: {
      nodes: BlogNode[];
    };
    lastPublished: {
      nodes: BlogNode[];
    };
    other: {
      nodes: BlogNode[];
    };
  };
}

const Blog: React.FC<BlogProps> = ({ data }) => {
  return (
    <main>
      <Layout
        breadcrumbItems={[{ title: 'Blog', href: '/blog/' }]}
        hideFilter
        hideBreadcrumb
        showFooterFilter
      >
        <div className="mt-4 flex  md:gap-[105px] justify-between md:justify-end">
          <a
            className="text-[#336a9d] font-semibold text-sm inter"
            href={'#lastPublished'}
          >
            Últimas postagens
          </a>
          <a
            className="text-[#336a9d] font-semibold text-sm inter"
            href={'#tutorial'}
          >
            Tutoriais
          </a>
          <a
            className="text-[#336a9d] font-semibold text-sm inter"
            href={'#other'}
          >
            Outros artigos
          </a>
        </div>

        <BlogCardList title={'Indicados para você'} background>
          {data.indicated.nodes.map(indicated => (
            <MinimalBlogCard
              title={indicated.frontmatter.title}
              coverImage={indicated.frontmatter.coverImage}
              categories={indicated.frontmatter.categories}
              slug={indicated.frontmatter.slug}
            />
          ))}
        </BlogCardList>

        <div className="hidden md:flex mt-[108px] gap-[21px]">
          <div className="w-[495px] h-[330px]">
            <MinimalBlogCard
              title={data.lastPublished.nodes[0].frontmatter.title}
              coverImage={data.lastPublished.nodes[0].frontmatter.coverImage}
              categories={data.lastPublished.nodes[0].frontmatter.categories}
              innerInfo
              yellowCategory
              slug={data.lastPublished.nodes[0].frontmatter.slug}
            />
          </div>

          <div className="w-full" id={'lastPublished'}>
            <BlogCardList title={'Últimas postagens'} withoutTopMargin>
              {data.lastPublished.nodes.map((indicated, index) => {
                if (index === 0) return;
                return (
                  <MinimalBlogCard
                    title={indicated.frontmatter.title}
                    coverImage={indicated.frontmatter.coverImage}
                    categories={indicated.frontmatter.categories}
                    slug={indicated.frontmatter.slug}
                    yellowCategory
                    leftBorder
                  />
                );
              })}
            </BlogCardList>
          </div>
        </div>

        <div className="md:hidden">
          <BlogCardList title={'Últimas postagens'}>
            {data.lastPublished.nodes.map((indicated, index) => {
              if (index === 0) {
                return (
                  <>
                    <div className="md:hidden">
                      <MediumBlogCard
                        title={indicated.frontmatter.title}
                        coverImage={indicated.frontmatter.coverImage}
                        categories={indicated.frontmatter.categories}
                        date={indicated.frontmatter.date}
                        description={indicated.excerpt}
                        largeMobile
                        slug={indicated.frontmatter.slug}
                      />
                    </div>

                    <div className="hidden md:block">
                      <MediumBlogCard
                        title={indicated.frontmatter.title}
                        coverImage={indicated.frontmatter.coverImage}
                        categories={indicated.frontmatter.categories}
                        date={indicated.frontmatter.date}
                        description={indicated.excerpt}
                        slug={indicated.frontmatter.slug}
                      />
                    </div>
                  </>
                );
              }

              return (
                <MediumBlogCard
                  title={indicated.frontmatter.title}
                  coverImage={indicated.frontmatter.coverImage}
                  categories={indicated.frontmatter.categories}
                  date={indicated.frontmatter.date}
                  description={indicated.excerpt}
                  slug={indicated.frontmatter.slug}
                />
              );
            })}
          </BlogCardList>
        </div>

        {/*<ReadAbout />*/}

        <div id={'tutorial'}>
          <BlogCardList title={'Tutoriais'}>
            {data.tutorial.nodes.map((indicated, index) => {
              if (index === 0) {
                return (
                  <>
                    <div className="md:hidden">
                      <MediumBlogCard
                        title={indicated.frontmatter.title}
                        coverImage={indicated.frontmatter.coverImage}
                        categories={indicated.frontmatter.categories}
                        date={indicated.frontmatter.date}
                        description={indicated.excerpt}
                        slug={indicated.frontmatter.slug}
                        largeMobile
                      />
                    </div>

                    <div className="hidden md:block">
                      <MediumBlogCard
                        title={indicated.frontmatter.title}
                        coverImage={indicated.frontmatter.coverImage}
                        categories={indicated.frontmatter.categories}
                        date={indicated.frontmatter.date}
                        description={indicated.excerpt}
                        slug={indicated.frontmatter.slug}
                      />
                    </div>
                  </>
                );
              }

              return (
                <MediumBlogCard
                  title={indicated.frontmatter.title}
                  coverImage={indicated.frontmatter.coverImage}
                  categories={indicated.frontmatter.categories}
                  date={indicated.frontmatter.date}
                  description={indicated.excerpt}
                  slug={indicated.frontmatter.slug}
                />
              );
            })}
          </BlogCardList>
        </div>

        <div id={'other'}>
          <BlogCardList title={'Outros artigos'}>
            {data.other.nodes.map(indicated => (
              <MediumBlogCard
                title={indicated.frontmatter.title}
                coverImage={indicated.frontmatter.coverImage}
                categories={indicated.frontmatter.categories}
                slug={indicated.frontmatter.slug}
              />
            ))}
          </BlogCardList>
        </div>
      </Layout>
    </main>
  );
};

export const query = graphql`
  query MyQuery {
    indicated: allMdx(
      filter: { frontmatter: { indicated: { eq: true } } }
      limit: 4
    ) {
      nodes {
        frontmatter {
          categories
          title
          slug
          coverImage {
            childImageSharp {
              gatsbyImageData(
                width: 495
                height: 330
                transformOptions: { fit: COVER }
              )
            }
          }
        }
      }
    }

    tutorial: allMdx(
      filter: { frontmatter: { categories: { in: "tutorial" } } }
      limit: 4
    ) {
      nodes {
        frontmatter {
          title
          categories
          date
          slug
          coverImage {
            childImageSharp {
              gatsbyImageData(
                width: 495
                height: 330
                transformOptions: { fit: COVER }
              )
            }
          }
        }
        excerpt
      }
    }

    lastPublished: allMdx(limit: 4, sort: { frontmatter: { date: DESC } }) {
      nodes {
        frontmatter {
          categories
          title
          slug
          coverImage {
            childImageSharp {
              gatsbyImageData(
                width: 495
                height: 330
                transformOptions: { fit: COVER }
              )
            }
          }
        }
        excerpt
      }
    }

    other: allMdx(limit: 8) {
      nodes {
        frontmatter {
          categories
          title
          slug
          coverImage {
            childImageSharp {
              gatsbyImageData(
                width: 495
                height: 330
                transformOptions: { fit: COVER }
              )
            }
          }
        }
        excerpt
      }
    }
  }
`;

export const Head: HeadFC = () => <SEO pathname={'/blog/'} />;

export default Blog;
