import React from 'react';
import * as classes from './minimal-blog-card.module.scss';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import clsx from 'clsx';

interface MinimalBlogCardProps {
  title: string;
  coverImage: {
    childImageSharp: {
      gatsbyImageData: any;
    };
  };
  categories: string[];
  innerInfo?: boolean;
  yellowCategory?: boolean;
  leftBorder?: boolean;
  slug: string;
}

export function MinimalBlogCard(props: MinimalBlogCardProps) {
  const {
    title,
    coverImage,
    categories,
    yellowCategory,
    innerInfo,
    leftBorder,
    slug,
  } = props;

  let image = getImage(coverImage);

  const renderCategories = () => (
    <div className={classes.categories}>
      {categories.map(category => (
        <div
          className={clsx({
            [classes.category]: true,
            [classes.yellowCategory]: yellowCategory,
          })}
        >
          {category}
        </div>
      ))}
    </div>
  );

  return (
    <a
      className={clsx({
        [classes.container]: true,
        [classes.innerContainer]: innerInfo,
        [classes.leftBorder]: leftBorder,
      })}
      href={`/blog/${slug}/`}
    >
      <div className={classes.imageContainer}>
        {image && (
          <GatsbyImage
            image={image}
            className={clsx({
              [classes.image]: true,
              [classes.largeImage]: innerInfo,
            })}
            alt={`Imagem de capa do post ${title}`}
          />
        )}
        <div
          className={clsx({
            [classes.innerContent]: true,
            [classes.padding]: innerInfo,
          })}
        >
          {innerInfo && (
            <>
              {renderCategories()}

              <h2 className={classes.innerTitle}>{title}</h2>
            </>
          )}
        </div>
      </div>

      {!innerInfo && (
        <>
          {renderCategories()}

          <h2 className={classes.outerTitle}>{title}</h2>
        </>
      )}
    </a>
  );
}
